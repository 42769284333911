import { Link, useNavigate } from 'react-router-dom';

const navigation = [
  { name: 'Product', href: '/product' },
  { name: 'Pricing', href: '/pricing' },
  { name: 'Team', href: '/team' },
  { name: 'Try it', href: 'https://app.tarn.co/' },
];

function NavigationMenu({ direction = 'horizontal', onLinkClick }) {
  const isVertical = direction === 'vertical';
  const isRouterAvailable = !!useNavigate; // Check if Router context is available

  return (
    <nav
      className={isVertical ? 'flex flex-col space-y-2' : 'flex gap-x-16'}
      aria-label="Navigation Menu"
    >
      {navigation.map((item) =>
        item.href.startsWith('http') || !isRouterAvailable ? (
          // External or fallback links use <a> tags
          <a
            key={item.name}
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onLinkClick} // Close menu on click
            className="block pt-2 text-sm font-semibold leading-6 text-gray-500 hover:text-indigo-500 transition-colors"
          >
            {item.name}
          </a>
        ) : (
          // Internal links use <Link>
          <Link
            key={item.name}
            to={item.href}
            onClick={onLinkClick} // Close menu on click
            className="block pt-2 text-sm font-semibold leading-6 text-gray-500 hover:text-indigo-500 transition-colors"
          >
            {item.name}
          </Link>
        )
      )}
    </nav>
  );
}

export default NavigationMenu;