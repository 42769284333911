import React from 'react';
import BackgroundGradient from '../ui/BackgroundGradient.jsx'; // Import the BackgroundGradient component
import LinkedIn from '../ui/icons/LinkedIn.jsx'; // Import the LinkedIn component

const person = {
  name: 'Josh Jackson',
  role: 'CEO & Founder',
  imageUrl: 'https://res.cloudinary.com/dnhbo3j62/image/upload/q_auto,f_auto/v1731767661/IMG_1303_2_k3bsgn.png',
  linkedInUrl: 'https://www.linkedin.com/in/josh-jackson-1491a131/', // LinkedIn profile URL
  bio: [
    "Hi, it’s me - Josh. I'm not going to write this in the third person.",
    "For over a decade, I’ve been lucky to experience a crash course in go-to-market operations. From &lt; $1M startups to $4B public companies, I’ve built and delivered operations across 10 person and 500 person go-to-market teams.",
    "I’ve come into organizations with no operations, and some with 20 years of legacy operations. I’ve fixed broken operations from multiple organizations <i>while</i> pushing them together. Of course, always surrounded by some stellar (and sometimes not) colleagues. I’ve seen some shit.",
    "I was taught early to treat operations like an internal product. User experience and approachability has always been important to me and the teams I’ve managed, and I’ve seen it directly correlated with the productivity of the sales, marketing, and customer success teams we’ve supported.",
    "<strong>I’m incredibly excited to bring this mindset to my own set of go-to-market products.</strong>",
    "Stay hydrated,",
    "Josh",
  ],
};

export default function Team() {
  return (
    <div className="relative isolate">
      {/* Background Gradient */}
      <BackgroundGradient />

      {/* Content */}
      <div className="pt-24 sm:pt-32">
        <div className="mx-auto max-w-4xl px-6 lg:px-10 text-center">
          <h2 className="text-5xl font-semibold tracking-tight text-gray-900">
            Nice to meet you.
          </h2>
        </div>
        <div className="mx-auto mt-20 px-6 lg:px-8 flex flex-col sm:flex-row items-center sm:items-center">
          <img
            alt="Josh Jackson"
            src={person.imageUrl}
            className="w-1/2 sm:w-1/3 max-w-[400px] rounded-2xl object-cover object-center mb-12 sm:mb-0 sm:mr-12"
          />
          <div className="text-left">
            <h3 className="text-4xl font-semibold tracking-tight text-gray-900">
              {person.name}
            </h3>
            <div className="mt-4 flex items-center text-2xl text-gray-600">
              <span>{person.role}</span>
              <LinkedIn url={person.linkedInUrl} />
            </div>
            <div className="mt-6 text-gray-600 space-y-4">
              {person.bio.map((paragraph, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}