import {
    BoltIcon,
    BeakerIcon,
    PencilSquareIcon,
    ArrowTopRightOnSquareIcon,
  } from '@heroicons/react/20/solid';
  
  export default function Step2Fixes() {
    return (
      <div id="step-2-fixes" className="relative isolate overflow-hidden mt-32">
        <div className="mx-auto max-w-7xl lg:px-6">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-16 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            {/* Left Side: Screenshot */}
            <div className="flex items-center justify-center">
              <img
                alt="Product screenshot"
                src="/assets/create-a-fix.gif"
                width={1216} // Half the original width
                height={721}
                className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
              />
            </div>
  
            {/* Right Side: Heading, Paragraph, and Features */}
            <div>
              <h2 className="text-4xl font-semibold tracking-tight text-gray-900">
                2. Create a <span className="text-indigo-600">Fix</span>
              </h2>
              <p className="mt-8 text-lg font-medium text-gray-500 sm:text-xl">
                Fix <strong>issues</strong> (records) you've found with your rule. <br /> Write a prompt
                describing how these issues should be resolved.
              </p>
              <dl className="mt-10 space-y-8 text-base/7 text-gray-600">
                {/* Feature 1 */}
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <BoltIcon
                      aria-hidden="true"
                      className="absolute left-1 top-1 h-6 w-6 text-indigo-600"
                    />
                    OpenAI does the heavy lifting.
                  </dt>{' '}
                  <dd className="inline">
                    Whether it's a simple fix or a complex set of flows, OpenAI models build out the
                    condition logic for you.
                  </dd>
                </div>
  
                {/* Feature 2 */}
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <BeakerIcon
                      aria-hidden="true"
                      className="absolute left-1 top-1 h-6 w-6 text-indigo-600"
                    />
                    Formula or AI fixes.
                  </dt>{' '}
                  <dd className="inline">
                    OpenAI models determine whether a fix can be run without AI, or if GPT is required
                    to process or update fields. Fixes can prioritize formula conditions (or use them
                    exclusively) to reduce{' '}
                    <a
                      href="https://tarn.co/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-600 hover:text-indigo-500 font-semibold inline-flex items-center"
                    >
                      credit costs
                      <ArrowTopRightOnSquareIcon
                        className="ml-1 h-4 w-4 text-indigo-600"
                        aria-hidden="true"
                      />
                    </a>
                    .
                  </dd>
                </div>
  
                {/* Feature 3 */}
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <PencilSquareIcon
                      aria-hidden="true"
                      className="absolute left-1 top-1 h-6 w-6 text-indigo-600"
                    />
                    AI reading & writing.
                  </dt>{' '}
                  <dd className="inline">
                    Fixes automatically include relevant fields as context when they're run. Have GPT
                    review fields on a record before taking action, and leverage GPT to update fields
                    with summaries, descriptions, and more.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }