import React from 'react';
import releaseNotes from '../data/release-notes/releaseNotes.json';
import { CheckCircleIcon, BugAntIcon, WrenchIcon } from '@heroicons/react/24/outline';
import BackgroundGradient from './ui/BackgroundGradient.jsx'; // Import the BackgroundGradient component

function ReleaseNotes() {
    // Sort the releases by date, most recent first
    const sortedReleases = releaseNotes.releases.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Helper function to return the appropriate icon based on the section title
    const getIconForSection = (title) => {
        switch (title) {
            case "Features":
                return <CheckCircleIcon className="h-6 w-6 text-green-500" />;
            case "Bug Fixes":
                return <BugAntIcon className="h-6 w-6 text-red-500" />;
            case "Maintenance":
                return <WrenchIcon className="h-6 w-6 text-blue-500" />;
            default:
                return null;
        }
    };

    // Render images as separate list items
    const renderImages = (item) => {
        return (
            item.image && (
                <li className="my-8 mr-8 flex justify-center">
                    <img
                        src={item.image}
                        alt="Release Screenshot"
                        className="rounded-md w-full sm:w-full md:w-2/3 lg:w-2/3 border border-gray-300"
                    />
                </li>
            )
        );
    };

    // Function to handle paragraphs/new lines in descriptions and convert URLs into links
    const renderDescription = (description) => {
        // Regex to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        return description.split("\n").map((line, index) => {
            // Split the line by URLs
            const parts = line.split(urlRegex);

            // Find the URLs (match returns an array or null)
            const urls = line.match(urlRegex);

            if (!urls) {
                // No URLs in this line, just render the line as is
                return <p key={index} className="mb-4 text-gray-700">{line}</p>;
            }

            // If URLs exist, interleave text segments with link elements
            const elements = [];
            let urlIndex = 0;
            for (let i = 0; i < parts.length; i++) {
                const text = parts[i];
                if (text) {
                    elements.push(text);
                }
                if (urlIndex < urls.length && parts[i + 1] !== undefined) {
                    const url = urls[urlIndex++];
                    elements.push(
                        <a
                            key={`url-${index}-${urlIndex}`}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-indigo-600 underline"
                        >
                            {url}
                        </a>
                    );
                }
            }

            return <p key={index} className="mb-4 text-gray-700">{elements}</p>;
        });
    };

    return (
        <div className="relative isolate pt-14">
            {/* Use the BackgroundGradient component */}
            <BackgroundGradient />

            {/* Content */}
            <div className="py-24 px-2 md:px-14 lg:px-14">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                            Release Notes
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Stay tuned.
                        </p>
                    </div>
                    {/* Rendering each release as a card */}
                    <ul className="mt-10 space-y-14">
                        {sortedReleases.map((release) => (
                            <li key={release.version} className="text-sm pt-4 px-6 border rounded-lg bg-white">
                                {/* Version number styled similar to Salesforce */}
                                <div className="text-3xl font-semibold text-[#8A79EC] mb-2">
                                    {release.version}
                                </div>

                                <div className="w-full flex justify-between items-center mb-2">
                                    <h2 className="text-xl font-semibold">
                                        {release.releaseTitle}
                                    </h2>
                                    <div className="text-sm text-gray-500 text-right mt-3">
                                        <span className="block font-semibold">
                                            {new Date(release.date).toLocaleTimeString('en-US', {
                                                hour: 'numeric',
                                                minute: '2-digit',
                                                hour12: true
                                            })}
                                        </span>
                                        <span className="block">
                                            {new Date(release.date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-8 text-gray-700 pt-3">
                                    {renderDescription(release.description)}
                                </div>

                                {/* Render each section (Features, Bug Fixes, Maintenance) */}
                                {release.sections.map((section) => (
                                    <div key={section.title} className="mb-6">
                                        <div className="flex items-center">
                                            {getIconForSection(section.title)}
                                            <h3 className="ml-2 font-medium text-gray-800">{section.title}</h3>
                                        </div>

                                        {/* Render text items and images as separate items */}
                                        <ul className="list-disc ml-8 mt-2">
                                            {section.items.map((item, index) => (
                                                item.text ? (
                                                    <li key={index} className="text-gray-600 pb-1">
                                                        {item.text}
                                                    </li>
                                                ) : (
                                                    renderImages(item)
                                                )
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ReleaseNotes;