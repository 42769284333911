import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import HeroSection from './components/HeroSection.js';
import ReleaseNotes from './components/ReleaseNotes.jsx';
import Testing from './components/Testing.jsx';
import Pricing from './components/pricing/Pricing.jsx';
import Team from './components/about/Team.jsx'; // Importing About component
import TermsConditions from './components/legal/TermsConditions.jsx';
import Product from './components/product/Product.jsx';
import DataPolicy from './components/legal/DataPolicy.jsx';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <Router>
      <div className="bg-white">
        <Header />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/product" element={<Product />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          <Route path="/testing" element={<Testing />} />
        </Routes>
        <Footer />
        <Analytics /> {/* Add this here */}
      </div>
    </Router>
  );
}

export default App;