import { ForwardIcon } from '@heroicons/react/24/outline';

export default function TarnDataBento() {
  return (
    <div id="landing-tarn-data" className="mt-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <p className="mt-2 max-w-lg text-pretty text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">
          3. <span className="text-indigo-600">Run it. </span>
        </p>
        <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
          <div className="relative lg:col-span-3">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
              <img
                alt=""
                src="assets/bento/01-Schedule.png"
                className="h-80 object-cover object-left sm:object-center"
              />
              <div className="p-10 pt-4">
                <h3 className="text-sm/4 font-semibold text-indigo-600">Schedule</h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">Ad-hoc or Scheduled runs</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Run your Rules & Fixes as needed, or schedule them to run hourly, daily, or weekly.
                </p>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
          </div>


          <div className="relative lg:col-span-3">
            <div className="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
              <img
                alt=""
                src="assets/bento/02-Audit.png"
                className="h-80 object-cover object-right sm:object-center"
              />
              <div className="p-10 pt-4">
                <h3 className="text-sm/4 font-semibold text-indigo-600">Audit</h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">Check your work</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  All record updates are saved with Salesforce ID, the field name, the old field value, and the new field value. Download an audit as CSV.
                </p>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
          </div>


          <div className="relative lg:col-span-2">
            <div className="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
              <img
                alt=""
                src="assets/bento/03-Super Run.png"
                className="h-80 object-cover object-left lg:object-right"
              />
              <div className="p-10 pt-4">
                <h3 className="text-sm/4 font-semibold text-indigo-600">Super Run</h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">Up to 2000 records</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Update 2000 records at a time with a formula fix, or 200 records at a time with an AI fix.
                </p>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
          </div>

          <div className="relative lg:col-span-2">
            <div className="absolute inset-px rounded-lg bg-white" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <img
                alt=""
                src="assets/bento/04-Live.png"
                className="h-80 object-cover object-left sm:object-center"
              />
              <div className="p-10 pt-4">
                <h3 className="text-sm/4 font-semibold text-indigo-600">Live</h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">Watch AI work</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Sit back, relax, and watch a stream of record updates in the Live view.
                </p>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
          </div>


          <div className="relative lg:col-span-2">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
              <img
                alt=""
                src="assets/bento/05-No Subscription.png"
                className="h-80 object-cover"
              />
              <div className="p-10 pt-4">
                <h3 className="text-sm/4 font-semibold text-indigo-600">No Subscriptions</h3>
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950">Only pay for what you use</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                  Access to all features. Buy credits as needed and track your credit usage in the app.
                </p>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
          </div>
        </div>
      </div>
    </div>
  );
}